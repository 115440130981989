:root {
    --color1: #0C2813;
    --color2: #DECB7A;
    --color3: #F0EEEC;
    --color4: #CCCCCC;
    --color5: #EBA9A6;
    --color6: #4B4E69;
    --color7: #63C038;
    --color8: #546859;
    --color9: #e4d594;
    --color10: #243d2a;
    --color11: #555555;
    --color12: #C3C3C3;
    --color13: #Eb0000;
  }
  
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
  @import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');
  
  @font-face {
    font-family: 'ThunderBold';
    src: url('../style/fonts/Thunder-BoldLC.otf');
    font-style: normal;
    font-weight: 900;
  }
  
  @font-face {
    font-family: 'ThunderLight';
    src: url('../style/fonts/Thunder-LightLC.otf');
    font-style: normal;
    font-weight: 500;
  }

  