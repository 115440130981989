@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --color1: #0C2813;
  --color2: #DECB7A;
  --color3: #F0EEEC;
  --color4: #CCCCCC;
  --color5: #EBA9A6;
  --color6: #4B4E69;
  --color7: #63C038;
  --color8: #546859;
  --color9: #e4d594;
  --color10: #243d2a;
  --color11: #555555;
  --color12: #C3C3C3;
  --color13: #Eb0000;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');

@font-face {
  font-family: 'ThunderBold';
  src: url('../style/fonts/Thunder-BoldLC.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'ThunderLight';
  src: url('../style/fonts/Thunder-LightLC.otf');
  font-style: normal;
  font-weight: 500;
}

body {
  margin: 0;
  background-color: #fff;
  color: var(--color1);
  font-family: 'Helvetica', 'Roboto', 'Arial' !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dk-only{
  display: flex;
}

.mb-only{
  display: none;
}

a {
  text-decoration: none !important;
  color: var(--color1);
}

p {
  font-size: 1.1rem;
  line-height: 1.35rem;
  margin-bottom: 15px;
}

.bg-icon{
  background-color: var(--color4);
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-left: 5px;
}

button{
  text-decoration: none;
}

h1, h2, h3, h4, h5{
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

h1{
  font-size: 3rem;
  line-height: 3.25rem;
}

h2{
  font-size: 2.5rem;
  line-height: 2.75rem;
}

h3 {
  font-size: 1.75rem;
  line-height: 2rem;
}

h5 {
  font-size: 20px;
  line-height: 24px;  
}

label {
  font-size: 1.1rem;
  line-height: 1.35rem;
  margin: 15px 0px 5px 0px;
}

input {
  font-family: 'Helvetica', 'Roboto', 'Arial' !important;
  outline: 0px; 
  border-radius: 10px;
  border: 3px solid var(--color4);
  background-color: white;
  padding: 16px;
  text-align: baseline;
  color: var(--color11);
}

input:focus {
  border: 3px solid var(--color6);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition: all 0.5s linear;
}

      
select {
  font-family: 'Helvetica', 'Roboto', 'Arial' !important;
  appearance: none; 
  outline: none;
  cursor: pointer;  color: none; 
  background-color: transparent;
  border: 0px solid none;
  border-radius: 10px;
  padding: 5px 10px;
  width: auto;
  height: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  text-align: center;
}

select:focus {
  outline: 0px dashed red; 
}

.button-submit{
  padding: 16px !important
}


.languageChange select {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  appearance: none; 
  outline: none;
  cursor: pointer; 
  color: var(--color3); 
  background-color: transparent;
  border: 1px solid var(--color3);
  border-radius: 20px;
  padding: 0px 20px;
  width: auto;
  height: 40px;
  text-align: center;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); */
}

.shopping-icon {
  padding: 2px 8px;
  margin: 0px 0px;
  border-radius: 20px;
  font-size: 10px;
  color: white;
  background: var(--color5) !important;
  position: relative;
  top: -10px;
  left: -5px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.button-cta{
  border-radius: 5px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.button-cta-quick{
  border-radius: 20px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.footer button {
  /* font-size: 0.75rem; */
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.topalert{
  background-color: var(--color2);
  justify-content: center;
  align-items: center;
}

.topalert h5{
  font-size: 20px;
  padding-top: 10px;
}

.container-standard{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 15px;
  min-height: 200px;
}

.bg-primary {
  background: repeating-linear-gradient(
    -45deg,
    var(--color1),
    var(--color10) 1px,
    var(--color1) 1px
  );
}

.bg-primary .button{
  border: 5px solid red;
}

.bg-secondary {
  background: repeating-linear-gradient(
    -45deg,
    var(--color2),
    var(--color9) 1px,
    var(--color2) 1px
  );
}

.bg-height {
  background: repeating-linear-gradient(
    -45deg,
    var(--color8),
    var(--color10) 1px,
    var(--color8) 1px
  );
}

.bg-five {
  background: repeating-linear-gradient(
    -45deg,
    var(--color5),
    var(--color13) 1px,
    var(--color5) 1px
  );
}

.bg-six {
  background: repeating-linear-gradient(
    -45deg,
    var(--color6),
    var(--color8) 1px,
    var(--color6) 1px
  );
}

.bg-three {
  background: repeating-linear-gradient(
    -45deg,
    var(--color3),
    var(--color4) 1px,
    var(--color3) 1px
  );
}

.fill-one{
  fill: var(--color1)
}

.stroke-one{
  stroke: var(--color1)
}

.svg-form{
  width: 20px;
  height: 20px;
}

 #hero h1{
  color: var(--color1);
  font-size: 4.5rem;
  line-height: 4.25rem;
 }

 .list-line{
  background-color: var(--color12);
  padding: 20px 20px 15px 20px;
  border-radius: 10px;
}

.list-line-title{
  font-family: 'ThunderLight';
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--color1);
  padding-top: 5px;
}

.list-line-img{
  background-color: red;
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

 .list-box{
  width: 150px;
  margin: 30px 15px
}

.list-box-title{
  font-family: 'ThunderLight';
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-box-icon{
  background-color: var(--color2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
 }

 .list-box-subtitle{
  font-family: 'ThunderLight';
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--color1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  text-align: center;
 }


@media (max-width: 1024px) {
  
  p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 15px;
  }

  h3{
    margin-bottom: 10px;
  }

  .dk-only{
    display: none;
  }

  .mb-only{
    display: flex;
  }

  .button-cta-quick {
    font-size: 1rem;
  }

}

/* Clean CSS */

.hero-title {
  letter-spacing: 2px;
  line-height: 1;
  font-weight: 600;
  font-size: 4.5rem;
  color: #333;
  text-transform: uppercase;
}



.bg-body {
  background-color: #dbd6d0 !important;
}

.menu-top {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: red;
}

.languageChange {

  color: #555;
  font-size: smaller;
  font-family: 'ThunderBold', 'Roboto', sans-serif;
}

.languageChange option{
  color: red;

}

.logoSRCC {
  width: 150px !important;
  height: auto;
}

/* Style the sticky */
#sticky {
  font-family: 'Helvetica', 'Roboto' !important;
  overflow: hidden;
  background-color: rgb(255, 255, 255, 0.8);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999999999;
}

/* sticky links */
#subheader a {
  float: left;
  display: block;
  color: #555;
  text-align: center;
  padding: 0px;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the sticky with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.footer {
  background: repeating-linear-gradient(
    -45deg,
    #0c2813,
    #243d2a 2px,
    #0c2813 2px
  );
  padding: 60px 0px 90px 0px;
  color: #dbd6d0;
}

.footer a {
  color: #dbd6d0 !important;
}

.footer h3 {
  color: #dbd6d0 !important;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 15px;
}

.special-1 {
  padding-bottom: 60px;
}

.hero {
  background-color: #dbd6d0 !important;
  padding: 110px 0px 110px 0px;
  margin: 0px 15px 60px 15px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 70vh;
}

.store-btn {
  background-color: #e51a1e !important;
  color: white !important;
}

.primary-btn {
  background: repeating-linear-gradient(
    -45deg,
    #0c2813,
    #243d2a 1px,
    #0c2813 1px
  );
  color: white !important;
}

.light-btn {
  background-color: #dbd6d0 !important;
  color: #555 !important;
}

.negative-btn {
  background-color: #fff !important;
  color: #555 !important;
}

.cta-btn {
  background: repeating-linear-gradient(
    -45deg,
    #decb7a,
    #e4d594 1px,
    #decb7a 1px
  );
  color: #0c2813 !important;
}

.controller-btn {
  padding: 0px !important;
  border-radius: 0 !important;
}

.remove-btn {
  padding: 0px !important;
  border-radius: 0 !important;
}

.controller-middle {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  font-size: 1.75rem;
  border-radius: 0 !important;
  color: #555 !important;
}

#productDetails {
  background-color: #efefef;
  margin-bottom: 100px;
}

#productDetails h4 {
  color: #333;
  font-weight: bold;
}

#productDetails p {
  color: #333;
}

#productDetails svg {
  color: #333;
}

#module-text-center {
  margin-top: 0px !important;
  margin-bottom: 30px !important;
}

#module-text-center h2 {
  border-bottom: 2px solid currentColor;
  display: inline-block;
  padding-bottom: 0px;
  margin-bottom: 15px;
}

.module-ShowcaseHorizontalTitle {
  margin-bottom: 15px !important;
  color: red !important;
}

#testGT {
  background: repeating-linear-gradient(
    -45deg,
    #2c3343,
    #26283a 1px,
    #2c3343 1px
  );
}

#testGT2 {
  background-color: green;
  color: white;
  background-image: radial-gradient(currentColor 0.5px, transparent 0.5px);
  background-size: calc(10 * 1px) calc(10 * 1px);
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #333;
}

.bento-grid-btn {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  letter-spacing: 1px;
  z-index: 10;
  font-size: 1rem;
  color: #0c2813;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: rgb(219, 214, 208, 0.7);
  margin: 15px;
  text-transform: uppercase;
}

#modul-ShowcaseHorizontal h5 {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  letter-spacing: 1px;
  z-index: 10;
  font-size: 1.25rem;
  text-transform: uppercase;
}

#BlockTextVideoColorRegularBeige {
  background-color: #dbd6d0;
  width: 100vw;
}

#BlockTextVideoColorDiagonalBeige {
  background: linear-gradient(-4deg, white 0%, white 10%, transparent 10%),
    linear-gradient(356deg, #dbd6d0 0%, #dbd6d0 90%, transparent 90%);
  padding: 100px 0px 100px 0px;
  margin-bottom: 60px;
  width: 100vw;
}

#LargeCta {
  background-color: #decb7a;
  background: white;
  background-image: linear-gradient(
      90deg,
      rgba(222, 203, 122, 0.5) 50%,
      transparent 0
    ),
    linear-gradient(rgba(222, 203, 122, 0.5) 50%, transparent 0);
  background-size: 5px 5px;
  font-family: 'ThunderBold', 'Roboto', sans-serif;
}

#LargeCta h2 {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  color: #0c2813;
  text-transform: uppercase;
  font-size: 4rem;
  letter-spacing: 1px;
}

#LargeCta p {
  font-family: 'Helvetica', 'Roboto', sans-serif;
  color: black;
}

#LargeCta ul li {
  font-family: 'Helvetica', 'Roboto', sans-serif;
  color: black;
}

#TextNegative {
  background-color: white;
  margin: -100px 00px 0px 00px;
}

#IconBottom {
  left: 80vw;
  bottom: 20vh;
}

.IconBottomDesign {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #25d366;
  z-index: 999999999;
  border-radius: 10000px;
  width: 60px;
}

.chronologyBox {
  background-color: #dbd6d0;
  padding: 20px;
}

.chronologyBox h3 {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  color: #0c2813;
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 1px;
  margin: 30px 0px 15px 0px;
}

.chronologyBox hr {
  border: 1px dotted #0c2813 !important;
  margin: 15px 0px;
}

.chronologyBoxLine {
  background-color: #decb7a;
}

.chronologyBoxRound {
  background-color: #dbd6d0;
  border: 4px solid #decb7a;
}

.StepByStepRound {
  background-color: #decb7a;
  border: 2px solid #0c2813;
}

.StepByStepStroke {
  color: #0c2813;
}

.StepByStepLine {
  background-color: #0c2813;
}

#StepByStep h5 {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  color: #0c2813;
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 1px;
  margin: 0px 0px 15px 0px;
}

.StepByStepReady {
  background-color: #0c2813;
  border: 2px solid #0c2813;
}

#ShortlinksRaw4 h5 {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  color: #0c2813;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin: 0px 0px 15px 0px;
}

.section-title {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  letter-spacing: 2px;
  line-height: 1;
  font-weight: 600;
  font-size: 3rem;
  color: #333;
  margin: 60px 0px 30px 0px;
  width: 100%;
  border-bottom: 1px solid #decb7a;
  padding-bottom: 10px;
}

#HomeTopAlert {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  text-transform: uppercase;
  color: #dbd6d0;
  background: repeating-linear-gradient(
    -45deg,
    #0c2813,
    #243d2a 1px,
    #0c2813 1px
  );
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
}

#CookieConsent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: repeating-linear-gradient(
    -45deg,
    #decb7a,
    #e4d594 1px,
    #decb7a 1px
  );
  color: #0c2813 !important;
  z-index: 10000000000;
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.4);
}

#CookieConsentPP{
  text-decoration: underline !important;
}



.card-title {
  font-family: 'ThunderBold', 'Roboto', sans-serif;
  letter-spacing: 1.15px;
  line-height: 1;
  font-weight: 600;
  font-size: 1.25rem;
  color: #333;
  margin: 5px 0px 0px 0px;
  width: 100%;
  border-bottom: 0px solid #decb7a;
  padding-bottom: 0px;
  text-transform: uppercase;
}



.shopping-list {
  padding: 2px 5px !important;
  padding: 0px 0px 0px 0px !important;
  background: none !important;
}

.cartZone {
  background: repeating-linear-gradient(
    -45deg,
    #0c2813,
    #243d2a 1px,
    #0c2813 1px
  );
  padding-top: 15px !important;
  height: 100vh;
}

.btn-close {
  --bs-btn-close-color: red !important
;
}

.cart-details {
  border-bottom: 1px solid white;
  color: white;
}

.offcanvas.offcanvas-end {
  z-index: 999999999999999;
  width:100vw !important;
}

#bookCard a{
  color: #CCC !important;
  text-decoration: underline  !important;
}

#newsletterPP a{
  color: #CCC !important;
  text-decoration: underline  !important;
}

@media (max-width: 1280px) {
  .hero {
    background-color: #dbd6d0 !important;
    padding: 80px 0px 110px 0px;
    margin: 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .hero-title {
    font-family: 'ThunderBold';
    letter-spacing: 0em;
    font-weight: 600;
    font-size: 3rem;
  }

  .hero-box h1 {
    font-family: 'ThunderBold';
    color: #333;
    font-weight: 700;
    padding-bottom: 30px;
    font-size: 4rem;
    line-height: 4rem;
  }

  .footer h2 {
    border-bottom: 1px solid white;
    margin-bottom: 15px;
  }

  .offcanvas.offcanvas-end {
    z-index: 999999999999999;
    width: 100vw !important;
  }

  #module-text-center {
    margin-top: 60px !important;
    margin-bottom: 30px !important;
  }

  #BlockTextVideoColorDiagonalBeige {
    background: #dbd6d0 0%;
    padding: 0px 0px 0px 0px;
    margin-bottom: 30px;
    width: 100vw;
  }

  .languageChange{
    background: none;
    color: white;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: 'ThunderBold', 'Roboto', sans-serif;
  }
}
.shadow-error {
     box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
     border:1px solid red;
}







