:root {
  --color1: #0C2813;
  --color2: #DECB7A;
  --color3: #F0EEEC;
  --color4: #CCCCCC;
  --color5: #EBA9A6;
  --color6: #4B4E69;
  --color7: #63C038;
  --color8: #546859;
  --color9: #e4d594;
  --color10: #243d2a;
  --color11: #555555;
  --color12: #C3C3C3;
  --color13: #Eb0000;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');

@font-face {
  font-family: 'ThunderBold';
  src: url('../style/fonts/Thunder-BoldLC.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'ThunderLight';
  src: url('../style/fonts/Thunder-LightLC.otf');
  font-style: normal;
  font-weight: 500;
}

.navbar {
  height: 80px;
  background-color: none;
  position: relative;
}

.logo {
  color: red;
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0;
}

.nav-elements li {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) a {
  margin-right: 45px; 
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #0c2813;
  /* text-transform: uppercase; */
}

.nav-elements ul li:last-child {
  background: transparent;
  font-weight: 700;
  border-radius: 9999px;
  color: #0c2813;
  padding: 12px 32px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/*
  line below if active
  .nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  */

@media (max-width: 1024px) {
  .nav-elements ul li:not(:last-child) a {
    font-family: 'ThunderBold', 'Helvetica' !important;
    letter-spacing: 2px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    color: white !important;
    margin-right: 0;
    font-size: 48px
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: repeating-linear-gradient(
      -45deg,
      #0c2813,
      #243d2a 1px,
      #0c2813 1px
    );
  }

  .nav-elements ul li a {
    font-family: 'ThunderBold', 'Helvetica' !important;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    color: white;
  }
  .nav-elements ul li a.active {
    color: #8da24d !important;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background: repeating-linear-gradient(
      -45deg,
      #0c2813,
      #243d2a 1px,
      #0c2813 1px
    );
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-elements.active {
    width: 100%;
    height: 100vh !important;
    z-index: 9999999999 !important;
    position: fixed !important;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }

  .nav-elements ul li a {
    font-size: 2.5rem;
    color: white;
  }

  .nav-elements ul li:last-child {
    background-color: var(--color2);
    padding: 10px 0px;
    margin-top: 30px;
  }

  .nav-elements ul li a:last-child {
    font-family: 'ThunderBold', 'Helvetica' !important;
    padding-bottom: 15px !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    text-transform: uppercase !important;
    color: white !important;
    padding-bottom: 30px !important;
    background-color: none !important;
    background: none !important;
    background: red;
  }

  .nav-elements ul li a.active {
    color: #8da24d;
  }


}
